import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import mixin from "./mixin/mixin.js"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './static/custom_animate.css'
import './static/common.css'

Vue.config.productionTip = false;

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.mixin(mixin)




new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

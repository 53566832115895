import Vue from 'vue';
import VueRouter from 'vue-router';
// import HomeView from '../pages/HomeView.vue';

Vue.use(VueRouter);

const routes = [
	// {
	//    path: '/',
	//    name: 'home',
	//    component: HomeView,
	//  },
	//  {
	//    path: '/about',
	//    name: 'about',
	//    // route level code-splitting
	//    // this generates a separate chunk (about.[hash].js) for this route
	//    // which is lazy-loaded when the route is visited.
	//    component: () => import(/* webpackChunkName: "about" */ '../pages/AboutView.vue'),
	//  },
	{
		path: '/',
		name: '首页',
		component: resolve => require(['../pages/index'], resolve),
		index: 0,
		meta: {
			id: "index",
			index: 0,
			isNav: true,
			// keepAlive: false,
			navTitle: '首页',
		}
	},
	{
		path: '/gwatech',
		name: '核心技术',
		component: resolve => require(['../pages/gwatech'], resolve),
		index: 1,
		meta: {
			id: 'gwatech',
			index: 1,
			isNav: true,
			// keepAlive: false, // 需要缓存
			navTitle: '核心技术'
		}
	}, {
		path: '/gwaproduce',
		name: '研发生产',
		component: resolve => require(['../pages/gwaProduce'], resolve),
		index: 2,
		meta: {
			id: 'gwaproduce',
			index: 2,
			isNav: true,
			// keepAlive: false, // 需要缓存
			navTitle: '研发生产'
		}
	}, {
		path: '/product',
		name: '产品应用',
		component: resolve => require(['../pages/gwaProduct'], resolve),
		index: 3,
		meta: {
			id: 'product',
			index: 3,
			isNav: true,
			// keepAlive: false, // 需要缓存
			navTitle: '产品应用'
		}
	}, {
		path: '/Intro',
		name: '关于我们',
		index: 4,
		component: resolve => require(['../pages/gwaIntro'], resolve),
		meta: {
			id: 'Intro',
			index: 4,
			isNav: true,
			// keepAlive: false, // 需要缓存
			navTitle: '关于我们'
		}
	}, {
		path: '/news',
		name: '公司新闻',
		component: resolve => require(['../pages/gwaNews'], resolve),
		index: 5,
		meta: {
			keepAlive: false, // 需要缓存
			id: 'news',
			index: 5,
			isNav: true,
			navTitle: '公司新闻'
		}
	},

];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,

	scrollBehavior(to, from, savedPosition) {
		return {
			x: 0,
			y: 0
		}
	},
});


// router.beforeEach((to, from, next) => {
//   if (from && to.path !== from.path) {
//     console.log('滚动到顶部')
//     window.scrollTo(0, 0)
//   }
//   next()
// })

export default router;
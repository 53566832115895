export default {
	data() {
		return {
			isMobile: false,
		}
	},
	mounted() {
		this.isMobile = this.isMobileDevice();
	},
	filters: {},
	methods: {
		//判断当前设备是否为移动端
		isMobileDevice() { 
			// const ua = navigator.userAgent.toLowerCase();
			// const t1 =
			// 	/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mac/i.test(
			// 		ua
			// 	);
			// const t2 =
			// 	ua.indexOf("windows") < 0 &&
			// 	ua.indexOf("iphone") < 0 &&
			// 	navigator.maxTouchPoints > 1; 
			// return t1 || t2 ;
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
			return flag;
		},

		imgUrlFormat(path) {
			return require('..//static//img//' + (this.isMobile ? 'sm' : 'lg') + path)
		}
	}
}

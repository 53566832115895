<template>
	<!-- scroll Manu -->
	<div class="head-contents" v-if="scrollHeight > 0">
		<div class="nav minswidth">
			<div class="logos" @click="handLogo"><img :src="imgUrlFormat(logoPath)" alt="" /></div>
			<div class="counts">
				<ul class="thead">
					<li v-for="(r,index) in this.$router.options.routes" :class="index == curIndex ? 'currIdex' : ''"
						@click="goAnchor(index,r)">
						{{ r.meta.navTitle }}
						<i></i>
					</li>
				</ul>
			</div>
		</div>
	</div>

	<!-- Manu-->
	<div ref="scrollView" class="header-container" v-else>
		<div class="header-content">
			<div class="topnav">
				<div class="logomin">
					<div class="logo" @click="handLogo"><img :src="imgUrlFormat(logoUrlParh)" alt="" /></div>
				</div>
				<div class="tpheads">
					<ul class="thead">
						<li v-for="(r,index) in this.$router.options.routes" :class="index == curIndex ? 'currIdex' : ''"
							@click="goAnchor(index,r)">
							{{ r.meta.navTitle }}
							<i></i>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'headNav',
		data() {
			return {
				scrollHeight: 0,
				logoPath: '/gwalogo.png',
				logoUrlParh: '/logo.png',
				curIndex: 0,

				mapicn: '/lange.png',
				touchStartPointX: 0,
				touchStartPointY: 0,
				distanceX: 0,
				distanceY: 0,
				transitionName: 'fade',
				currtIndex: 0,
				loadingIndex: 0,
				isClick: false,
				srcollActive: true,
			}
		},
		watch: {
			//监听路由变化
			'$route'(to, from) {
				if (to.query.toPage) {
					this.curIndex = to.query.toPage;
				}
				if (to.query.curIndex) {
					this.curIndex = to.query.curIndex
				}
			},
			immediate: true
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll);
		},

		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll);
		},

		methods: {
			handleScroll() {
				this.scrollHeight = window.scrollY; // 获取滚动高度
			},

			handLogo() {
				this.$router.replace({
					path: './',
					query: {
						toPage: 0
					}
				})
			},

			goAnchor(i, val) {
				if (this.$router.currentRoute.path !== val.path) {
					this.$router.push({
							path: val.path
						})
						.catch(err => {
							if (err.name !== 'NavigationDuplicated') {}
						});
				}
				this.curIndex = i;
			},
		}
	}
</script>

<style>
	.header-container {
		display: flex;
		margin-top: 2.5rem;
		/* height: 5.0625rem; */
		width: 100%;
		position: relative;
	}

	.header-content {
		display: flex;
	}

	.topnav {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.header-container::before {
		content: "";
		display: block;
		flex: 1;
		background: #1B4895;
	}

	.header-container::after {
		/* background-color: rgba(0, 0, 0, .3); */
		background: #fff;
		content: "";
		display: block;
		flex: 1;
	}

	.logomin {
		width: 24%;
		margin-right: 2%;
		height: 5rem;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #1B4895;
	}

	.tpheads {
		/* width: 74%; */
		width: 100%;
		height: 5.125rem;
		/* height: 100%; */
		display: flex;
		justify-content: center;
		align-items: center;
		/* background-color: rgba(0, 0, 0, .3); */
		background: #fff;
	}

	.tpheads ul {
		width: 100%;
		display: flex;
		justify-content: end;
	}

	.thead li {
		width: 4.5rem;
		text-align: center;
		height: 100%;
		margin-left: 3.125rem;
		font-size: 1rem;
		cursor: pointer;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.thead li a {
		color: #333;
		text-decoration: none;
	}

	img {
		width: 100%;
		height: 100%;
		display: inline-block;
	}

	/* 滚动时菜单样式 */
	.head-contents {
		width: 100%;
		background-color: #fff;
		opacity: .9;
		position: fixed;
		top: 0;
		z-index: 111;
		box-shadow: 0 2px 5px hsla(0, 0%, 87.5%, .5);
		height: 5.125rem;
		display: flex;
	}

	.logos {
		/* width: 13.4375rem;
		 height: 4.0625rem; */
		height: 10.1875rem;
		height: 3.25rem;
		display: inline-block;
		margin: .375rem 0;
	}

	.nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.counts ul {
		display: flex;
	}

	ul li.currIdex i {
		width: 100%;
		position: absolute;
		left: 0;
		bottom: -2px;
		display: inline-block;
		border-bottom: 2px solid #1B4895;
		margin-top: -0.625rem !important;
	}

	.header-content {
		width: 1200px;
	}

	@media (min-width: 992px) {}

	/* 小屏 */
	@media (max-width: 992px) {}
</style>
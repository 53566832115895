import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		scrollTop: 0,
	},
	getters: {},
	mutations: {
		setScrollTop(state, scrollTop) {
			state.scrollTop = scrollTop;
		}
	},
	actions: {
	},
	modules: {},
});
<template>
	<div id="app">
		<div class="fixed-top">
			<headNav></headNav>
		</div>

		<transition>
			<keep-alive>
				<router-view />
			</keep-alive>
		</transition>
	</div>
</template>


<script>
	import store from './store';
	import headNav from '../src/components/headNav.vue';
	export default {
		name: 'app',
		components: {
			headNav
		},

		data() {
			return {
			}
		},

		mounted() {
			window.addEventListener('scroll', this.handleScroll);
		},

		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll);
		},

		methods: {
			handleScroll() {
				// this.scrollWidHeight = window.scrollY; // 获取滚动高度
				store.commit('setScrollTop', window.pageYOffset || document.documentElement.scrollTop);
			},
		}

	}
</script>


<style>
	@import '~animate.css';

	body {
		/* 禁用默认的下拉刷新和边界效果,但是依然可以进行滑动导航 */
		overscroll-behavior-y: none;
	}

	#app {
		width: 100vw;
		/* height: 100vh; */
	}

	.header-container {
		position: absolute;
		z-index: 11;
	}

	.mainsd {
		min-height: 500px;
		position: relative;
	}

	.fixhead {
		z-index: -0.0625rem;
	}

	.wrap {
		position: absolute;
		top: 0px;
		bottom: 0px;
		/* overflow: hidden; */
		overflow-x: hidden;
		width: 100vw;
		/* min-height: 100vh; */
	}


	.fade-enter-to,
	.fade-leave {
		opacity: 1;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.3s ease;
	}


	.slide-down-enter,
	.slide-up-leave-to {
		opacity: 0;
		transform: translateY(100%)
	}

	.slide-down-leave-to,
	.slide-up-enter {
		opacity: 0;
		transform: translateY(-100%)
	}

	.slide-down-enter-active,
	.slide-down-leave-active,
	.slide-up-enter-active,
	.slide-up-leave-active {
		transition: 1s;
		position: absolute;
		top: 0;
		width: 100%;
	}


	.navbar-dark .navbar-nav .nav-link {
		color: rgba(255, 255, 255, 0.5);
	}

	.set:hover {
		color: hsla(0, 0%, 100%, .75) !important;
	}



	@media (min-width: 992px) {
		.navbar {
			height: 5rem;
			padding: 0 7rem !important;
			background-color: #fff;
			opacity: 0.9;
			filter: blur(0px);
		}

		.navbar-dark .navbar-brand {
			/* color: rgba(0,0,0,0.5) !important; */
			font-size: 13px;
		}


		.logo {
			height: 3.5rem;
		}

		.navbar-nav .nav-link {
			color: rgba(0, 0, 0, 0.5) !important;
			font-size: 1rem !important;
		}

		.navbar-nav .check_color .nav-link {
			color: #333 !important;
		}

	}

	@media (min-width: 1920px) {
		.navbar {
			padding: 0 10rem !important;
		}
	}


	/* 小屏 */
	@media (max-width: 992px) {
		.bg-nav {
			background-color: rgba(0, 0, 0, 0.4) !important;
		}

		.navbar {
			padding: 0 !important;
		}

		.logo {
			height: 2rem;
			margin-left: 0.5rem;
		}

		.navbar-toggler {
			margin-right: 0.5rem;
			padding: 0.3125rem 0.5rem !important;
			border: none !important;
		}

		.set {
			line-height: 3rem !important;
		}

		.navbar-nav .nav-link {
			padding: 0;
		}

	}
</style>